import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import ApiLoader from "../../components/common/ApiLoader";
import TicketAcitivtyCreateForm from "../../components/modules/tickets/activity/TicketActivityCreateForm";
import { AppHeaderComponent, TicketActivitiesApi } from "@unity/components";
import dayjs from "dayjs";

export default function TicketActivityCreate({ context, route }) {
  const [loading, setLoading] = useState({ status: false, data: "Loading..." });
  const id = route.match.params.id;
  const [data, setData] = useState({});

  let history = useHistory();

  const handleSave = async () => {
    setLoading({
      status: true,
      data: "Ticket Activity Creating! Please Wait....",
    });

    const res = await TicketActivitiesApi.createActivity(id, data);

    setLoading({
      status: true,
      data: "Ticket Activity Created! Please Wait....",
    });
    setTimeout(() => {
      history.goBack();
      setLoading({ status: false, data: "" });
    }, 5000);
  };

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  const handleDateChange = (name, date) => {
    const newData = { ...data };
    newData[name] = dayjs(date).toISOString();
    setData(newData);
  };

  return (
    <>
      <AppHeaderComponent
        context={context}
        theme={context.theme}
        name={"tickets"}
        subpage={"activity"}
        subpage2={"create"}
        saveBtn={handleSave}
      />
      <ApiLoader status={loading.status} data={loading.data} />
      <div style={{ paddingTop: 140 }}>
        <TicketAcitivtyCreateForm data={data} handleChange={handleChange} handleDateChange={handleDateChange} />
      </div>
    </>
  );
}
