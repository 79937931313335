import React, { useEffect, useState } from "react";
import { TicketApi, NotificationsApi } from "@unity/components"
import { useHistory } from "react-router";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ChatMessageItemCustomer from "./chat/ChatMessageItem";
import ChatMessageItemUser from "./chat/ChatMessageItemUser";
import './chat/root.css';
import SendIcon from '@mui/icons-material/Send';

export default function LiveChat({ context, route, data, channel }) {

    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [chat, setChat] = useState([]);
    

    const handleMessageSend = async () => {
        var obj = {
            chat_id: chat.id,
            body: message,
            is_customer: false,
            username: context.auth.name,
            send_email: true,
            contact_email: data.clientEmail
        }

        const res = await NotificationsApi.chatStore(obj)

        setMessage("")
    }

    const getData = async () => {

        const res = await NotificationsApi.chatIndexByTicket(route.location.state.id)

        if (res.error) return setMessages([])

        setChat(res.data.length > 0 ? res.data[0] : res.data)

        {
            res.data.length > 0 &&
            channel.bind(`chat-ticket-${res.data[0].id}`, async function (e) 
            {
            const res = await NotificationsApi.chatIndexByTicket(route.location.state.id);
            setChat(res.data[0])
            })
        }
    }

    const loadData = async () => {
        setLoading(true)
        await getData()
        setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, []
    )


    return (
        <div style={{ background: '#f2f2f2', height: '92vh' }}>
            <div className="container-fluid">
                <div className="row">
        
        <div className="col-lg-9" style={{ height: "89vh", paddingLeft: 0 }}>

                        { loading ?
                        <h1>Loading...</h1> :
                        data && !loading &&
                            <div className="block" id="chat-window" style={{ height: "100%", padding: 20, boxShadow: "none", overflowY: "scroll", paddingBottom: 90, paddingTop: 10 }}>
                                <p className="chat-title" style={{ marginBottom: 30 }}>Ticket {data.reference_id}</p>
                                {data && chat.messages.map((item, key) => {
                                    if (item.is_customer) {
                                        return <ChatMessageItemCustomer data={item} />
                                    } else {
                                        return <ChatMessageItemUser data={item} />
                                    }
                                })}

                                <div className="chat-actions">
                                    <div className="row">
                                        <textarea style={{borderRadius: "1rem 0px 0px 1rem"}} onChange={(e) => setMessage(e.target.value)} value={message} type="text" placeholder="Type message" className="col-lg-11" />
                                        <button style={{border: "none", backgroundColor: "#1b00a4", color: "white", borderRadius: "0px 1rem 1rem 0px" }} className="col-lg-1" onClick={() => handleMessageSend()}><SendIcon /></button>
                                    </div>
                                </div>

                            </div>}

                    </div>

            {data &&
                <div className="col-lg-3" style={{ height: "89vh", padding: 0 }}>
                    <div className="block" style={{ height: "60%", padding: 20, borderRadius: 10 }}>
                        <p style={{ fontSize: "1.5rem", marginBottom: 20, fontWeight: 500 }} >Contact Information</p>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                                <div style={{ flex: 1 }}><EmailIcon /></div>
                                <div style={{ flex: 4 }}>{data.clientEmail}</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1 }}><PhoneIcon /></div>
                                <div style={{ flex: 4 }}>{data.clientNumber}</div>
                            </div>
                        </div>
                        <p style={{ fontSize: "1.5rem", marginBottom: "1.5rem", fontWeight: 500, marginTop: "3rem" }}>Ticket Information</p>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                                <div style={{ flex: 2 }}>Name:</div>
                                <div style={{ flex: 4 }}>{data.ticket_name}</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                                <div style={{ flex: 2 }}>Reported By: </div>
                                <div style={{ flex: 4 }}>{data.reported_by}</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                                <div style={{ flex: 2 }}>Company: </div>
                                <div style={{ flex: 4 }}>{data.clientName}</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                                <div style={{ flex: 2 }}>Notes: </div>
                                <div style={{ flex: 4 }}><button onClick={() => el()}>View</button></div>
                            </div>
                        </div>
                    </div>

                    <div className="block" style={{ height: "30%", borderRadius: 10 }}>
                        <div class="mapouter"><div class="gmap_canvas">
                            <iframe width="330" height="205" id="gmap_canvas" src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                            <a href="https://www.whatismyip-address.com/divi-discount/"></a>
                        </div>

                        </div>
                    </div>
                </div>
            }
       </div>
       </div>
       </div>
    )
}