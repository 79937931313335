import React, { useEffect, useState } from "react";
import Loading from "../components/common/Loading";
import TicketDefaultForm from "../components/modules/tickets/TicketDefaultForm";
import { AppHeaderComponent, TicketApi, BusinessApi } from "@unity/components";

export default function TicketView({ context, route }) {
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState({});
  const [busAddress, setBusAddress] = useState(false)

  const handleCall = async () => {
    const result = await TicketApi.getTicketRecord(route.match.params.id);
    setData(result.data);
    fetchFullBusiness(result.data)
  };

  const fetchFullBusiness = async (ticket) => {

    let res = null;
    let siteId = null;
    let siteName = null

    try {
      if(ticket.business_id == null || ticket.business_id == undefined){
        res = await BusinessApi.businessShow(ticket.customer_id);

        if( ticket.business_site_id == null || ticket.business_site_id == undefined ){
          siteId = ticket.address_id;
        }else{
          siteId = ticket.business_site_id;
        }
      }else{
        res = await BusinessApi.businessShow(ticket.business_id);

        siteId = ticket.business_site_id;
      }

      if (!res.success) throw "Call Failed";

      res.data.sites.map( site => {
        if( site._id == ticket.business_site_id){
          siteName = site;
        }
      })

      if( siteName != null && siteName != undefined) {
        setBusAddress( `${siteName["house_no"]} ${siteName["street"]}, ${siteName["postcode"]}, ${siteName["town"]} ` );
      }

    } catch (error) {
      alert(`Failed to Load Business - ${error}`);
    }
    return res;
  };

  useEffect(() => {
    handleCall();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          name={"tickets"}
          subpage={"view"}
          subpage2={data.ticket_name}
          context={context}
          theme={context.theme}
        />
        <div style={{ paddingTop: 140, paddingBottom: 40 }}>
          <TicketDefaultForm data={data} type="view" errors={errors} busAddress={busAddress}/>
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
