import { Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Loading from "../components/common/Loading";
import ApiLoader from "../components/common/ApiLoader";
import TicketCreateForm from "../components/modules/tickets/TicketCreateForm";
import TicketActivitiesIndex from "./activity/index";
import {
  AppHeaderComponent,
  TicketApi,
  TicketActivitiesApi,
  CodesApi,
  NotificationsApi,
  BusinessApi,
  PersonnelApi
} from "@unity/components";
import LiveChat from "./liveChat";

export default function TicketEdit({ route, context, channel }) {
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState({ status: false, data: false });
  const [companies, setCompanies] = useState(false);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [com_type, setComType] = useState([]);

  const [tab, setTab] = useState(0);
  const [activities, setActivities] = useState([]);
  const [previousAllocate, setPreviousAllocate] = useState("");
  const [selected, setSelected] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [sites, setSites] = useState([]);
  const [noneCustomer, setNoneCustomer] = useState(true);
  const [tags, setTags] = useState(false);

  const [initLoad, setInitLoad] = useState(true); //This bool is to ensure all the other data has been loaded before being set to false

  const handleTabs = (e, value) => {
    setTab(value);
  };

  function TabPanel(props) {
    const { children, value, index } = props;
    return <div>{value === index && <>{children}</>}</div>;
  }

  const handleSave = async () => {
    setLoading({ status: true, data: "Saving Records.. Please Wait !" });

    // These are fixes so they can initially save a customer ticket!
    if (data["com_type_id"]) {
      data["com_type"] = com_type[data["com_type_id"]];
    }
    if (data["category_id"]) {
      const catObj = categories.find((e) => e.id === data["category_id"]);
      data["category"] = catObj.name;
    }

    const result = await TicketApi.updateTicket(
      route.match.params.id,
      data
    );

    if(result.data && data.allocate_id && previousAllocate != "") {
      
      let payload = {
        "user_uuid": data.allocate_id,
        "name": "Ticket Allocated to You",
        "description": `Name: ${data.ticket_name} - ID: ${data.id} - Customer: ${data.clientName}`,
        "link": window.location.hostname + `/tickets/edit/${data.id}`,
        "link_action": "View Ticket",
        "poster": context.auth.name,
        "channel": data.allocate_id,
        "event": "notification",
        "message": "New Ticket Allocation"
      }
      const res = await NotificationsApi.saveNotification(payload); // Notify New Allocate

      payload.user_uuid = previousAllocate;
      payload.channel = previousAllocate;
      payload.name = "Ticket Unallocated From You"
      payload.message = "Ticket Unallocated";

      const res2 = await NotificationsApi.saveNotification(payload); // Notify Old Allocate
    }

    setLoading({ status: true, data: "Success, Please Wait ...." });

    setTimeout(() => {
      //history.push("/tickets/index");
      getData();
    }, 3000);
  };

  const getData = async () => {
    setLoading({status: true, data: "Loading Form Data..."});
    let selectedBusiness = null;

    const result = await TicketApi.getTicketRecord(route.match.params.id);
    setData(result.data);

    if(result){
      if((result.data.business_id != null && result.data.business_id != undefined) || (result.data.customer_id != null && result.data.customer_id != undefined)) {
        setNoneCustomer(false);
        selectedBusiness = await fetchFullBusiness(result);
        
        // We just set the array of businesses to the one business we have on an edit so that the AutoComplete works!
        setCompanies([selectedBusiness.data]);
      }
    }
   
    const setup = await TicketApi.getTicketSetup();
    setGroups(setup.data.groups);
    setCategories(setup.data.types);
    setComType(setup.data.com_types);

    const personnel = await PersonnelApi.getPersonnel();
    setUsers(personnel.data);

    const tags = await CodesApi.getTicketStates();
    setTags(tags.data);

    const act = await TicketActivitiesApi.getActivityIndex(route.match.params.id);
    setActivities(act.data);

    setLoading({status: false, data: false});
  };

  const loadData = async () => {
    setInitLoad(true);
    await getData();
    setInitLoad(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  const handleCompanySelect = async (obj) => {
    const newData = { ...data };

    newData["clientName"] = obj.customer_name;
    newData["clientEmail"] = obj.email;
    newData["clientNumber"] = obj.phone;
    newData["business_id"] = obj._id;

    fetchBusinessDetails(obj);

    setData(newData);
  };

  const handleNoCustomerSelect = (name, value) => {
    const newData = { ...data };
    newData["clientName"] = value;

    setData({ ...data, [name]: value });
    setErrors({});
  };

  const handleChange = async (name, value) => {
    if(name === "allocate_id" && previousAllocate === "") {
      setPreviousAllocate( value != data["allocate_id"] ? data["allocate_id"] : "" );
    }

    const newData = { ...data };
    newData[name] = value;
    setData(newData);
  };

//--------------------------------------------------------------------------------------------
// This function gets the full business from a ticket record
  const fetchFullBusiness = async (ticket) => {
    setLoading(true);
    let res = null;
    let siteId = ticket.data.business_site_id ? ticket.data.business_site_id : ticket.data.address_id ? ticket.data.address_id : ticket.data.business_site_id;
    let duplicatesiteChecker = false; //This is for ticked where the business has been merged to get the parent bus site and contact info
    let dupeBusId;

    try {
      res = await BusinessApi.businessShow(ticket.data.business_id ? ticket.data.business_id : ticket.data.customer_id);

      if (!res.success) throw "Call Failed";

      if (res.data.duplicates) {
        for (var i = 0; i < res.data.duplicates.length; i++) {
          if (ticket.data.business_id == res.data.duplicates[i]._id) {
            duplicatesiteChecker = true;
            dupeBusId = res.data.duplicates[i].maybeDuplicate;
            break;
          }
        }
      }

      if (res.data.duplicates && duplicatesiteChecker) {

        handleDupeSiteContact(dupeBusId, ticket)

      } else {

        if (res.data.sites) {
          setSites(res.data.sites);
        }

        setContacts([]);
        const tempSelected = { ...selected };
        tempSelected.business = res.data;
        tempSelected.site = tempSelected.site;
        tempSelected.contact = tempSelected.contact;

        setSelected(tempSelected);

        handleSiteContact(tempSelected, ticket.data, siteId);
      }

    } catch (error) {
      alert(`Failed to Load Business (with extra) - ${error}`);
    }
    setLoading(false);
    return res;
  };

  //This function will get the site contact using the merged businesses parent id
  const handleDupeSiteContact = async (originalBusId, ticket) => {

    const res = await BusinessApi.businessShow(originalBusId);

    const siteId = ticket.data.business_site_id;

    if (!res.success) throw "Call Failed";

    setSites(res.data.sites);

    setContacts([]);
    const tempSelected = { ...selected };
    tempSelected.business = res.data;
    tempSelected.site = tempSelected.site;
    tempSelected.contact = tempSelected.contact;

    setSelected(tempSelected);

    handleSiteContact(tempSelected, ticket.data, siteId);

  };


  const handleSiteContact = (sel , comparisonData, siteI) => {

    let arrayTest = []
    const tempSite = {...sel}

    // tempSite.business = comparisonData

    if(siteI){
    sel.business.sites.map((site) => {
      if(site._id == siteI){
        tempSite.site = site;
      }

      arrayTest.concat(site.contacts.map((index) => {
        return arrayTest.push(index);
      }
      ))
    })
    }

    setContacts(arrayTest)

    if(arrayTest.length > 0){
      arrayTest.map(
        x => {
          if( x._id == comparisonData.business_contact_id){
            tempSite.contact = x;
          }
        }
      )
    }

    setSelected( tempSite )
  }
//--------------------------------------------------------------------------------------------
// This function gets the full business from a business record
  const fetchBusinessDetails = async (business) => {
    setLoading(true);
    let res = null;
    try {
      res = await BusinessApi.businessShow(business._id);
      if (!res.success) throw "Call Failed";
      setSites(res.data.sites); 

      const tempData = { ...data };
      //tempData.customer_id = business._id;
      tempData.business_id = business._id;
      tempData.site_id = tempData.site_id;
      tempData.site_contact_id = tempData.site_contact_id;
      tempData.company_name = res.data.customer_name;
      tempData.sic_code = res.data.sic_code;
      tempData.website = res.data.website;
      tempData.num_employees = isNaN(res.data.number_of_employees)
        ? 0
        : res.data.number_of_employees;
      tempData.industry = res.data.industry;  

      setData(tempData);  

      setContacts([]);
      const tempSelected = { ...selected };
      tempSelected.business = res.data;
      tempSelected.site = tempSelected.site;
      tempSelected.contact = tempSelected.contact;
      setSelected(tempSelected);
      fetchSiteContacts(tempSelected)
    } catch (error) {
      alert(`Failed to Load Business - ${error}`);
    }
    setLoading(false);
    return res;
  };  

  const fetchSiteContacts = (sel) => {  

    let arrayTest = []
    sel.business.sites.map((site) => {
      arrayTest.concat(site.contacts.map((index) => {
        return arrayTest.push(index);
      }
      ))
    })
    setContacts(arrayTest)
  }
//--------------------------------------------------------------------------------------------

  const handleSiteChange = async (site, contact) => {

    setLoading(true);
    try {

      const tempData = { ...data };
      tempData.business_site_id = site._id;
      tempData.client_address = `${site.house_no} ${site.street} ${site.postcode}`;
      tempData.clientPostcode = site.postcode;
      tempData.clientTown = site.town;

      await setData(tempData);

      if (selected.contact && selected.site != null) {
        if (selected.site._id == selected.contact._site_id) {
          selected.contact = null
        }
      }

      const tempSelected = { ...selected };

      if(site.target){
        site.map( s => {
          if( s._site_id == site.target.value ){
            tempSelected.site = s;
          }
        })
      }else{
      tempSelected.site = site;}

      if (contact != null || contact != undefined) {
        tempSelected.contact = contact
      }

      setSelected(tempSelected);

      setContacts(site.contacts);
    } catch (error) {
      alert(`Failed to Switch Sites - ${error}`);
    }
    setLoading(false);
  };

  const handleContactChange = async (contact) => {
    
    setLoading(true);
    try {
      const tempData = { ...data };
      tempData.site_contact_name = contact.site_contact_name;
      tempData.clientNumber = contact.phone;
      tempData.clientEmail = contact.email;
      tempData.site_id = contact._site_id
      tempData.business_contact_id = contact._id;
      await setData(tempData);

      const tempSelected = { ...selected };
      tempSelected.contact = contact;
      setSelected(tempSelected);

      if (selected.contact && selected.site == null || selected.site == undefined) {
        const selectedSite = sites.filter(x => {
          if (x._id == tempData.site_id) {
            return x
          }
        })
        if (selectedSite[0] != null || selectedSite[0] != undefined) { handleSiteChange(selectedSite[0], tempSelected.contact) }
      }

    } catch (error) {
      alert(`Failed to Switch Contacts - ${error}`);
    }
    setLoading(false);
  };

  //if (data && com_type && companies && selected) {
    if (initLoad && data) {
    return <Loading />; 
  } else {
    return (
      <>
        <AppHeaderComponent
          name={"tickets"}
          subpage={"edit"}
          subpage2={data.ticket_name}
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
        />
        <ApiLoader status={loading.status} data={loading.data} />

        <div style={{ paddingTop: 140 }}>
          <Tabs value={tab} onChange={handleTabs}>
            <Tab label="Ticket Overview" />
            <Tab label={`Ticket Activities, ${data.activity_count}`} />
            <Tab label='Live Chat' />
          </Tabs>

          {tab === 0 && (
            <TicketCreateForm
              groups={groups}
              handleChange={handleChange}
              data={data}
              type="edit"
              companies={companies}
              users={users}
              com_type={com_type}
              categories={categories}
              handleCompanySelect={handleCompanySelect}
              noneCustomer={noneCustomer}
              handleNoCustomerSelect={handleNoCustomerSelect}
              setCompanies={setCompanies}
              errors={errors}
              tags={tags}
              context={context}
              loading={loading}
              contacts={contacts}
              handleContactChange={handleContactChange}
              handleSiteChange={handleSiteChange}
              selected={selected}
              sites={sites}
              setSelected={setSelected}
            />
          )}

          <TabPanel value={tab} index={1}>
            <TicketActivitiesIndex
              data={activities}
              route={route}
              context={context}
              ticket_id={route.match.params.id}
            />
          </TabPanel>

          <TabPanel value={tab} index={2}>
            <LiveChat 
              route={route} 
              context={context}
              data={data}
              channel={channel}
            />
          </TabPanel>
        </div>
      </>
    );
  }
}
