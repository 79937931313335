import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppButton from "../../components/common/AppButton";
import { Button, Fab, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { CanUpdate } from "../../services/Permissions";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';

const DEFAULTPERPAGE = 25

export default function TicketActivitiesIndex({
  route,
  context,
  ticket_id,
  data,
}) {

  const [searchedText, setSearchedText] = useState('')
  const [searchedTag, setSearchedTag] = useState('')
  const [searchedDepartment, setSearchedDepartment] = useState('')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(DEFAULTPERPAGE)
  const [productTotal, setProductTotal] = useState(0)

  const history = useHistory();

  const columns = [
    { headerName: "Activity Name", field: "name", flex: 1 },
    { headerName: "Who worked on it?", field: "completed_by", flex: 1 },
    { headerName: "Start Date/Time", field: "start", flex: 1,
    valueGetter: (cellValues) => {
      if (cellValues.row.created_at) {
        return dayjs(cellValues.row.start).format('DD/MM/YYYY')
      } else {
        return <span>No valid date</span>
      }
    }},
    { headerName: "Finish Date/Time", field: "end", flex: 1,
    valueGetter: (cellValues) => {
      if (cellValues.row.created_at) {
        return dayjs(cellValues.row.end).format('DD/MM/YYYY')
      } else {
        return <span>No valid date</span>
      }
    } },
    {
      headerName: "Actions",
      field: "",
      flex: 0.5,
        renderCell: (cellValues) => {
          const id = cellValues.row.ticket_id;
          const activityid = cellValues.row.id;
          const edit = CanUpdate("tickets");

          return (
            <div>
              <Tooltip title={"View"}>
                <Button
                  style={{
                    color: context.theme.sidebar.background,
                    backgroundColor: "transparent",
                    boxShadow: "none"
                  }}
                  onClick={(event) => { 
                    history.push({
                    pathname: `/tickets/${id}/activity/view/${activityid}`,
                    state: { ...cellValues.row }
                  }); 
                }}
                  variant="contained"
                ><VisibilityIcon /></Button>
              </Tooltip>

              {edit && (
                              <Tooltip title={"Edit"}>
                              <Button
                                style={{
                                  color: context.theme.sidebar.background,
                                  backgroundColor: "transparent",
                                  boxShadow: "none"
                                }}
                                onClick={(event) => { 
                                  history.push({
                                  pathname: `/tickets/${id}/activity/edit/${activityid}`,
                                  state: { ...cellValues.row }
                                }); 
                              }}
                                variant="contained"
                              ><EditIcon /></Button>
                            </Tooltip>
              )}
            </div>
          );
      },
    },
  ];

  return (
    <div id="companies">
      <div
        className="addCompanyRow"
        style={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Link to={{ pathname: `/tickets/${ticket_id}/activity/create` }}>
          <AppButton
            text="Add New Activity"
            btnIcon="add"
            style={{
              background: context.theme.sidebar.background,
              color: "white",
            }}
          />
        </Link>

        <Link
          to={{
            pathname: "/activities/communication/client/8/all/index",
            state: { filter: "Ticket" },
          }}
        >
          <AppButton
            text="View Activities"
            btnIcon="visibility"
            style={{
              background: context.theme.sidebar.background,
              color: "white",
            }}
          />
        </Link>
      </div>

      <div style={{height: '800px'}}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50, 100]}
        page={page}
        onPageSizeChange={(newSize) => {
          handlePageSizeChange(newSize);
        }}
        onPageChange={(newPage) => {
          handlePageChange(newPage);
        }}
        rowCount={productTotal}
        pagination
        paginationMode="server"
        density="compact"
        getRowId={(param) => param.id}
        //autoHeight
        
        sx={{
          boxShadow: 2,
         
                        overflowX: "hidden",
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: "0px"
          },
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}

        components={{
          Toolbar: () => (
            <div style={{
              margin: "0px",
              padding: "10px",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"

            }}>
              <Typography
                style={{ color: context.theme.sidebar.font, fontWeight: "bold", fontSize: "1.5rem" }}
              >
                Active Deals
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1vw"
                }}
              >
                <TextField
                  style={{ backgroundColor: "white" }}
                  id="activity-search"
                  defaultValue={searchedText}
                  placeholder='Search'
                  variant="standard"
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      getData(page, document.getElementById('ticket-search').value , pageSize);
                    }
                  }}
                />

                <Tooltip title={"Search"}>
                  <Button
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                    
                    }}
                    onClick={() => { getData(page, document.getElementById('ticket-search').value, pageSize, searchedTag, searchedDepartment) }}
                    variant="contained"
                  ><SearchIcon /></Button>
                </Tooltip>

                <Tooltip title={"Clear search"}>
                  <Button
                    style={{
                      background: "red",
                      color: "white",
                   
                    }}
                    onClick={() => { getData(page, "", pageSize, "", "") }}
                    variant="contained"
                  ><ClearIcon /></Button>
                </Tooltip>

              </div>
            </div>
          )
        }}
      />
      </div>
    </div>
  );
}
