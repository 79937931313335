import React from "react";
import './root.css';


export default function ChatMessageItemUser({ data }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
            <div style={{ flex: 4 }}>
                <div className="user-box">{data.body}</div>
            </div>

            <div style={{ flex: 1, textAlign: 'right' }}>
                <img src={`https://eu.ui-avatars.com/api/?name=${data.username}&rounded=true`} />
            </div>


        </div>
    )
}