import React from "react";
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from "dayjs";
import { TextField, InputLabel } from "@mui/material";

export default function TicketAcitivtyCreateForm({ data, handleChange, handleDateChange }) {


  return (
    <div className="block">
      <div className="container-fluid">
        <div className="form-row">
          <div className="form-group col-lg-12">
            <h4>Ticket Activity</h4>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-3">
            <TextField
              required
              label="Activity Name"
              type="text"
              name="name"
              fullWidth
              value={data.name} //In the form component, create the first input, and set its value to the current state of the ticket name
              onChange={(e) => handleChange(e)}
              helperText="Give the activity a name..."
            />
          </div>
          <div className="form-group col-lg-3">
            <TextField
              required
              label="Completed By"
              type="text"
              fullWidth
              name="completed_by"
              helperText="Who worked on this"
              value={data.completed_by}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="form-group col-lg-3">
            {/* <InputLabel
              style={{
                fontSize: 12,
                marginBottom: 2,
                color: "#2100b7",
              }}
            >
              Date/Time Started
            </InputLabel>
            <TextField
              required
              type="datetime-local"
              fullWidth
              name="start"
              helperText="data and time started"
              value={data.start}
              onChange={(e) => handleChange(e)}
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <DesktopDatePicker
                    required
                    fullWidth
                    name='start' 
                    label='Start Date'
                    inputFormat="DD/MM/YYYY"
                    helperText="data and time started"
                    value={ dayjs(data.start) || ""}
                    onChange={(e) => { handleDateChange('start', e.$d) } }
                    renderInput={(params) => <TextField {...params} />}
                />     </Stack>
                </LocalizationProvider>
          </div>
          <div className="form-group col-lg-3">
            {/* <InputLabel
              style={{
                fontSize: 12,
                marginBottom: 2,
                color: "#2100b7",
              }}
            >
              Date/Time Completed
            </InputLabel>
            <TextField
              required
              type="datetime-local"
              fullWidth
              name="end"
              helperText="data and time started"
              value={data.end}
              onChange={(e) => handleChange(e)}
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <DesktopDatePicker
                    required
                    fullWidth
                    name='end' 
                    label='End Date'
                    inputFormat="DD/MM/YYYY"
                    helperText="data and time started"
                    value={ dayjs(data.end) || ""}
                    onChange={(e) => { handleDateChange('end', e.$d) } }
                    renderInput={(params) => <TextField {...params} />}
                />     </Stack>
                </LocalizationProvider>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-12">
            <TextField
              label="Activity Notes"
              type="text"
              name="notes"
              fullWidth
              value={data.notes} //In the form component, create the first input, and set its value to the current state of the ticket name
              onChange={(e) => handleChange(e)}
              multiline
              helperText="Give the activity a name..."
            />
          </div>
        </div>
      </div>
    </div>
  );
}
