import { TextField } from "@material-ui/core";
import React, { useContext, useState } from "react";
import AuthContext from "../../../services/AuthContext";
import AppButton from "../../common/AppButton";
import Loading from "../../common/Loading";
import { KnowledgeBaseApi } from "@unity/components";

export default function KnowledgeBaseSearch() {
  const context = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [data, setData] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    const res = await KnowledgeBaseApi.searchKnowledge(query);
    setData(res.data);
    setLoading(false);
  };

  return (
    <div className="block">
      <TextField
        label="Search Knowledge Base"
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        fullWidth
      />
      {!loading ? (
        <AppButton
          btnIcon="search"
          text="search"
          style={{
            background: context.theme.sidebar.background,
            color: "white",
            width: "100%",
            marginTop: 20,
            marginBottom: 20,
          }}
          onClick={() => handleClick()}
        />
      ) : (
        <Loading />
      )}
      {data &&
        data.length > 0 &&
        data.map((item, key) => {
          return (
            <div
              key={key}
              className="container-fluid"
              style={{
                borderBottomColor: "black",
                borderBottomWidth: 4,
                borderBottomStyle: "solid",
              }}
            >
              <div className="form-row">
                <div className="form-group col-lg-4">
                  <TextField fullWidth label="Topic" value={item.topic} />
                </div>
                <div className="form-group col-lg-4">
                  <TextField fullWidth label="Subject" value={item.subject} />
                </div>
                <div className="form-group col-lg-4">
                  <TextField fullWidth label="Problem" value={item.problem} />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-lg-12">
                  <TextField fullWidth label="Fix" value={item.body} />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
