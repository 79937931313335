import React, { useEffect, useState } from "react";
import { CodesApi } from "@unity/components";

import { codeAlias } from "../../constants";
import TicketSettingsOptions from "./TicketSettingsOptions";

export default function TicketSettings() {
  const [data, setData] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const getData = async () => {
    const res = await CodesApi.getCodes();

    const tags = res.data.find(
      (res) => res.const_alias === codeAlias["TicketTags"]
    );

    setData({ tags_id: tags.id, tags: [...tags.options] });
  };

  const handleAdd = (type) => {
    const newData = { ...data };
    const oid = type + "_id";
    newData[type].push({ type_id: data[oid] });
    setData(newData);
  };

  const handleItemsUpdate = async (type) => {
    const len = data[type].length;

    for (let i = 0; i < len; i++) {
      if (data[type][i].update && data[type][i].id) {
        await CodesApi.updateOption(data[type][i].id, data[type][i]);
      } else if (data[type][i].update) {
        await CodesApi.createOption(data[type][i]);
      } else {
        // Nothing as this will not have a name added so would fail validation!
      }
    }

    setEditActive(false);
  };

  const handleItemSaveCancel = (type, key) => {
    const newData = { ...data };
    newData[type].splice(key, 1);
    setData(newData);
  };

  const handleItemChange = (e, type, key) => {
    const newData = { ...data };
    newData[type][key]["name"] = e.target.value;
    newData[type][key]["update"] = true;
    setData(newData);
  };

  const handleItemDelete = async (id) => {
    await CodesApi.deleteOption(id);
    await getData();
  };

  const handleItemRestore = async (id) => {
    await CodesApi.restoreOption(id);
    await getData();
  };

  useEffect(() => {
    getData();
  }, []);

  if (data) {
    return (
      <>
        <div className="row">
          <div className="col-lg-3">
            <TicketSettingsOptions
              title="Ticket Status"
              data={data.tags}
              fields="text"
              type="tags"
              editActive={editActive}
              setEditActive={setEditActive}
              handleItemsUpdate={handleItemsUpdate}
              handleItemSaveCancel={handleItemSaveCancel}
              handleItemDelete={handleItemDelete}
              handleItemRestore={handleItemRestore}
              handleItemChange={handleItemChange}
              handleAdd={handleAdd}
            />
          </div>
        </div>
      </>
    );
  } else return <>Loading....</>;
}
