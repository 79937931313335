import React, { useState, useEffect } from "react";
import { TicketApi, CodesApi, StorageManager } from "@unity/components";
import { CanDelete, CanUpdate } from "../../../services/Permissions";
import { useHistory } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { Button, Fab, TextField, Tooltip, Typography, Box, Skeleton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from "dayjs";

const DEFAULTPERPAGE = 25
const sm = new StorageManager()

export default function MyTickets({ context }) {
  const [data, setData] = useState(false);
  const [tags, setTags] = useState([{}]);
  const [searchedText, setSearchedText] = useState('')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(DEFAULTPERPAGE)
  const [productTotal, setProductTotal] = useState(0)
  const [loading, setLoading] = useState(true)

  const [tableSkeleton, setTableSkeleton] = useState(false);

  const LoadingSkeleton = () => (
    <Box
      sx={{
        height: "max-content"
      }}
    >
      {[...Array(10)].map((_) => (
        <Skeleton animation="wave" variant="rectangular" sx={{ my: 3, mx: 1, height: 24 }} />
      ))}
    </Box>
  );

  const history = useHistory();

  const handleLinkClick = (event, params, path) => {
    event.preventDefault();
    history.push({
      pathname: `/tickets/${path}/${params.id}`,
      state: { ...params }
    });
  };

  const getData = async (passedPage = page, passedSearch = searchedText, passedPageSize = pageSize) => {
    setTableSkeleton(true);
    const tags = await CodesApi.getTicketStates();
    setTags(tags.data);

    const res = await TicketApi.getIndexPaginate({
      read_all: false,
      pagination: true,
      page: passedPage + 1,
      per_page: passedPageSize,
      search: passedSearch,
      tag: null,
      department: null,
      closed: false
    });

    if (!res) return alert("Unable to load data");

    sm.setItem("last-searched-tickets-myTickets", passedSearch);

    setSearchedText(passedSearch)
    setData(res.data)
    setPageSize(passedPageSize)
    setPage(passedPage)
    setProductTotal(res.total)
    setTableSkeleton(false);
  };

  const loadData = async () => {
    setLoading(true)
    const initSearchedText = sm.getItem("last-searched-tickets-myTickets", searchedText) ?? searchedText;
    const initPage = sm.getItem("last-page-tickets-myTickets", page) ?? page;
    let initPageSize = sm.getItem("last-pagesize-tickets-myTickets", pageSize) ?? pageSize;
  
    if (typeof initPageSize === "string" && initPageSize != null) initPageSize = parseInt(initPageSize);

    await getData(initPage, initSearchedText, initPageSize)
    setLoading(false)
  } 

  useEffect(() => {
    loadData();
  }, []);

  const handlePageSizeChange = async (newSize) => {
    setLoading(true);
    sm.setItem("last-pagesize-tickets-myTickets", newSize);
    await getData(page, searchedText, newSize)
    setLoading(false);
  }

  const handlePageChange = async (newPage) => {
    setLoading(true);
    sm.setItem("last-page-tickets-myTickets", newPage);
    await getData(newPage, searchedText, pageSize);
    setLoading(false);
  }

  const columns = [
    { headerName: "Date Created", field: "created_at", flex: 1, minWidth: 130,
    renderCell: (cellValues) => {
      if (cellValues.row.created_at) {
        return dayjs(cellValues.row.created_at).format('DD/MM/YYYY HH:mm')
      } else {
        return <span>No valid date</span>
      }
    } },
    { headerName: "Ticket Name", field: "ticket_name", flex: 1.5, minWidth: 200 },
    { headerName: "Ref", field: "id", flex: 0.5 },
    { headerName: "Nature", field: "category", flex: 0.7 },
    { headerName: "Customer", field: "clientName", flex: 1, minWidth: 150 },
    { headerName: "Department", field: "group_name", flex: 1, minWidth: 150},
    { headerName: "Assigned", field: "allocate", flex: 1, minWidth: 150 },
    {
      headerName: "Status",
      field: "current_status",
      flex: 0.5,
      
      valueGetter: (cellValues) => {

          if (cellValues.row.current_status === 0) {
            return "Open";
          } else {
            return "Closed";
          }
        },
   
    },
    {
      headerName: "Tag",
      field: "tag",
      flex: 0.8,
      valueGetter: (cellValues) => {
          const tagId = cellValues.row.tag;

          if (tagId != null) {
            const data = tags.find((tag) => tag.id == tagId);

            return data.name;
          } else return "No Tag Set";
        },
     
    },
    // { 
    //   headerName: "Priority",
    //   label: "",
    //   flex: 1,
    //     renderCell: (cellValues) => {
    //       return cellValues.row.ticket_prioritised ? (
    //         <div class="priority">
    //           <Icon>whatshot</Icon>
    //         </div>
    //       ) : (
    //         <></>
    //       );
    //     },
    
    // },
    {
      headerName: "Action",
      field: "",
      flex: 1,
      minWidth: 130,
        renderCell: (cellValues) => {
          const edit = CanUpdate("tickets");

          return (
            <div style={{ display: "flex" }}>
              <Tooltip title={"View"}>
                <Button
                  style={{
                    color: context.theme.sidebar.background,
                    backgroundColor: "transparent",
                    boxShadow: "none"
                  }}
                  onClick={(event) => { handleLinkClick(event, cellValues.row, "view") }}
                  variant="contained"
                ><VisibilityIcon /></Button>
              </Tooltip>

              {edit && (
                <Tooltip title={"Edit"}>
                <Button
                  style={{
                    color: context.theme.sidebar.background,
                    backgroundColor: "transparent",
                    boxShadow: "none"
                  }}
                  onClick={(event) => { handleLinkClick(event, cellValues.row, "edit") }}
                  variant="contained"
                ><EditIcon /></Button>
              </Tooltip>
              )}

            </div>
          );
        },
    }
  ];


  if (loading) return (<h1>Loading...</h1>);

  if (!data || !Array.isArray(data)) return (<h1>No records found</h1>);

    return(
    <>
<div style={{height: '800px'}}>
      <DataGrid
        rows={ tableSkeleton ? [] : data } 
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50, 100]}
        page={page}
        onPageSizeChange={(newSize) => {
          handlePageSizeChange(newSize);
        }}
        onPageChange={(newPage) => {
          handlePageChange(newPage);
        }}
        rowCount={productTotal}
        pagination
        paginationMode="server"
        density="compact"
        getRowId={(param) => param.id}
        //autoHeight
        sx={{
          boxShadow: 2,
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: "0px"
          },
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
        loading={tableSkeleton}
        components={{
          LoadingOverlay: LoadingSkeleton,
          
          Toolbar: () => (
            <div style={{
              margin: "0px",
              padding: "10px",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"

            }}>
              <Typography
                style={{ color: context.theme.sidebar.font, fontWeight: "bold", fontSize: "1.5rem" }}
              >
                 
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1vw"
                }}
              >
                <TextField
                  style={{ backgroundColor: "white" }}
                  id="ticket-search"
                  defaultValue={searchedText}
                  placeholder='Search'
                  variant="standard"
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      getData(page, document.getElementById('ticket-search').value , pageSize);
                    }
                  }}
                />

                <Tooltip title={"Search"}>
                  <Button
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                    
                    }}
                    onClick={() => { getData(page, document.getElementById('ticket-search').value , pageSize) }}
                    variant="contained"
                  ><SearchIcon /></Button>
                </Tooltip>

                <Tooltip title={"Clear search"}>
                  <Button
                    style={{
                      background: "red",
                      color: "white",
                   
                    }}
                    onClick={() => { getData(page, "", pageSize) }}
                    variant="contained"
                  ><ClearIcon /></Button>
                </Tooltip>

              </div>
            </div>
          )
        }}
      />
      </div>
    </>
    )
}
