import React, { useState } from "react";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { DocumentVaultAPI, TicketApi } from "@unity/components";
import UploadFileIcon from '@mui/icons-material/UploadFile';

export default function AttachmentForm(props) {
    const [uploadData, setUploadData] = useState({});
    const [uploading, setUploading] = useState(false);

    const handleInputChange = async (event, index) => {
        setUploading(true);
        const files = event.target.files;
        let reader = new FileReader();
        for (let i = 0; i < files.length; i++) {
            reader.readAsDataURL(files[i]);
            reader.onload = (e) => {
                storeDocument({
                    base64: e.target.result,
                    name: files[i].name,
                    type: files[i].type,
                    size: files[i].size
                },
                    index);
            }
        };
    };

    const storeDocument = async (obj, lineIndex) => {
        const up = await DocumentVaultAPI.storeDocument({
            documents: [obj],
            application_ref: props.context.auth.app_uuid,
            public: 0
        });
       
        handleFileUploadedData(up)
    };

    const handleFileUploadedData = async (vault) => {
    
        const docsData = { 
          "document_id": vault.data[0].id,
          "document_name": vault.data[0].name,
          "size": vault.data[0].size,
          "raw_type": vault.data[0].raw_type,
          "created_at": vault.data[0].created_at,
          "ticket_id": props.ticket_id
        }

        const docRes = await TicketApi.ticketDocumentStore(docsData);
        if(docRes.status != 200) return alert("Document failed to upload")
        
        setUploading(false);
        props.triggerChange()
    
      }

    return (
        <>
            <div className="form-row">
                <div className="form-group col-lg-4">
                    {/* <FileBase64 multiple={true} onDone={(e) => handleUpload(e)} /> */}
                    <Tooltip title={"Upload"}>
                         
                         {uploading ?
                            <CircularProgress color="success" size={24} /> 
                            :
                        <Button
                            variant="contained"
                            component="label"
                            onChange={(evt) => handleInputChange(evt)}
                        >
                            <UploadFileIcon />
                            <input
                                type="file"
                                hidden={true}
                            />
                        </Button>}
                    </Tooltip>
                    <i style={{ marginLeft: "1rem" }}>Max Size 5MB</i>
                </div>
                <div className="form-group col-lg-4">
                </div>
            </div>
        </>
    )
}