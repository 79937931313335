import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import ApiLoader from "../../components/common/ApiLoader";
import Loading from "../../components/common/Loading";
import TicketAcitivtyCreateForm from "../../components/modules/tickets/activity/TicketActivityCreateForm";
import { AppHeaderComponent, TicketActivitiesApi } from "@unity/components";
import dayjs from "dayjs";

export default function TicketActivityEdit({ context, route }) {
  const [loading, setLoading] = useState({ status: false });
  const id = route.match.params.id;
  const activityid = route.match.params.activityid;
  const [data, setData] = useState(false);

  let history = useHistory();

  const handleSave = async () => {
    setLoading({
      status: true,
      data: "Ticket Activity Updating! Please Wait....",
    });

    await TicketActivitiesApi.updateActivity(id, activityid, data);

    setLoading({
      status: true,
      data: "Ticket Activity Updated! Please Wait....",
    });
    setTimeout(() => {
      history.goBack();
    }, 5000);
  };

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  const handleCall = async () => {
    const result = await TicketActivitiesApi.getActivityRecord(id, activityid);
    setData(result.data);
  };

  const handleDateChange = (name, date) => {
    const newData = { ...data };
    newData[name] = dayjs(date).toISOString();
    setData(newData);
  };

  useEffect(() => {
    handleCall();

    return () => {};
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name={"tickets"}
          subpage={"activity"}
          subpage2={"edit"}
          saveBtn={handleSave}
        />
        <ApiLoader status={loading.status} data={loading.data} />
        <div style={{ paddingTop: 120 }}>
          <TicketAcitivtyCreateForm data={data} handleChange={handleChange} handleDateChange={handleDateChange} />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
