import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ApiLoader from "../components/common/ApiLoader";
import KnowledgeBaseSearch from "../components/modules/knowledge/knowledgeBaseSearch";
import TicketCreateForm from "../components/modules/tickets/TicketCreateForm";
import { AppHeaderComponent, TicketApi, CodesApi, NotificationsApi, BusinessApi, PersonnelApi } from "@unity/components";
import { CollectionsBookmarkRounded } from "@material-ui/icons";

export default function TicketsCreate({ context, route }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({ status: false, data: false });
  const [companies, setCompanies] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [com_type, setComType] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [errors, setErrors] = useState({});
  const [selected, setSelected] = useState()
  const [contacts, setContacts] = useState([]);
  const[sites, setSites] = useState([])
  const [tags, setTags] = useState(false);
  const [noneCustomer, setNoneCustomer] = useState(false);

  let history = useHistory();

  const handleSave = async () => {
    setErrors({});
    if (!data.clientName) {
      setErrors({ company_name: true });
    } else if (!data.clientNumber) {
      setErrors({ clientNumber: true });
    } else if (!data.clientEmail) {
      setErrors({ clientEmail: true });
    } else if (!data.category_id) {
      setErrors({ category_id: true });
    } else {
      setLoading({ status: true, data: "Saving Records.. Please Wait !" });
      data["com_type"] = com_type[data["com_type_id"]];
      const catObj = categories.find((e) => e.id === data["category_id"]);
      data["category"] = catObj.name;

      const res = await TicketApi.createTicket(data);

      const ticket = res.data;

      if (res.data && data.allocate_id) {
        let payload = {
          "user_uuid": data.allocate_id,
          "name": "New Ticket Allocated to You",
          "description": `Name: ${data.ticket_name} - ID: ${ticket.id} - Customer: ${data.clientName}`,
          "link": window.location.hostname + `/tickets/edit/${ticket.id}`,
          "link_action": "View Ticket",
          "poster": context.auth.name,
          "channel": data.allocate_id,
          "event": "notification",
          "message": "New Ticket Allocation"
        }
        const res = await NotificationsApi.saveNotification(payload); // Notify New Allocate
      }

      setLoading({ status: true, data: "Success, Please Wait ...." });
      setTimeout(() => {
        history.push(`/tickets/edit/${ticket.id}`);
      }, 4000);
    }
  };
 
  const getData = async () => {
    setLoading({ status: true, data: "Loading Form Data" });
    const setup = await TicketApi.getTicketSetup();
    setGroups(setup.data.groups);
    setCategories(setup.data.types);
    setComType(setup.data.com_types);

    const users = await PersonnelApi.getPersonnel();
    if (users.data == undefined || users.data == null) return alert("Failed to pull users");
    setUsers(users.data);

    const clients = await BusinessApi.businessIndexFiltered({
      rules: [{ field: "is_client", operator: "=", value: true }],
      page: 1
    });

    if (clients.data == undefined || clients.data == null) return alert("Failed to load clients");
    setCompanies(clients.data.data);

    const tags = await CodesApi.getTicketStates();
    setTags(tags.data);
    setLoading({ status: false, data: false });
  };

  const fetchFullBusiness = async (business) => {
    setLoading(true);
    let res = null;
    try {
      res = await BusinessApi.businessShow(business._id);
      if (!res.success) throw "Call Failed";
      setSites(res.data.sites);

      const tempData = { ...data };
      //tempData.customer_id = business._id;
      tempData.business_id = business._id;
      tempData.site_id = tempData.site_id;
      tempData.site_contact_id = tempData.site_contact_id;
      tempData.company_name = res.data.customer_name;
      tempData.sic_code = res.data.sic_code;
      tempData.website = res.data.website;
      tempData.num_employees = isNaN(res.data.number_of_employees)
        ? 0
        : res.data.number_of_employees;
      tempData.industry = res.data.industry;

      setData(tempData);

      setContacts([]);
      const tempSelected = { ...selected };
      tempSelected.business = res.data;
      tempSelected.site = tempSelected.site;
      tempSelected.contact = tempSelected.contact;
      setSelected(tempSelected);
      handleSiteContact(tempSelected)
    } catch (error) {
      alert(`Failed to Load Business - ${error}`);
    }
    setLoading(false);
    return res;
  };

  const handleSiteContact = (sel) => {

    let arrayTest = []
    sel.business.sites.map((site) => {
      arrayTest.concat(site.contacts.map((index) => {
        return arrayTest.push(index);
      }
      ))
    })
    setContacts(arrayTest)
  }

  const handleSiteChange = async (site, contact, contactDataToBeSaved) => {

    setLoading(true);
    try {
      let tempData = { ...data };
      tempData.business_site_id = site._id;
      tempData.client_address = `${site.house_no} ${site.street} ${site.postcode}`;
      tempData.clientPostcode = site.postcode;
      tempData.clientTown = site.town;
      if(contactDataToBeSaved != null || contactDataToBeSaved != undefined){
        tempData = { ...tempData , ...contactDataToBeSaved }
      }

      await setData(tempData);

      if (selected.contact && selected.site != null) {
        if (selected.site._id == selected.contact._site_id) {
          selected.contact = null
        }
      }

      const tempSelected = { ...selected };
      tempSelected.site = site;
      if (contact != null || contact != undefined) {
        tempSelected.contact = contact
      }

      setSelected(tempSelected);

      setContacts(site.contacts);
    } catch (error) {
      alert(`Failed to Switch Sites - ${error}`);
    }
    setLoading(false);
  };

  const handleContactChange = async (contact) => {
    setLoading(true);
    try {
      const tempData = { ...data };
      tempData.site_contact_name = contact.site_contact_name;
      tempData.clientNumber = contact.phone;
      tempData.clientEmail = contact.email;
      tempData.site_id = contact._site_id
      tempData.business_contact_id = contact._id;

      let saveContactData = tempData
      await setData(tempData);

      const tempSelected = { ...selected };
      tempSelected.contact = contact;
      setSelected(tempSelected);

      if (selected.contact && selected.site == null || selected.site == undefined) {
        const selectedSite = sites.filter(x => {
          if (x._id == tempData.site_id) {
            return x
          }
        })
        if (selectedSite[0] != null || selectedSite[0] != undefined) { handleSiteChange(selectedSite[0], tempSelected.contact, saveContactData) }
      }

    } catch (error) {
      alert(`Failed to Switch Contacts - ${error}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCompanySelect = async (obj) => {
    const newData = { ...data };

    newData["clientName"] = obj.customer_name;
    newData["business_id"] = obj._id;
    
    fetchFullBusiness(obj);
    const address = await TicketApi.getAddressesIndex(obj._id);
    setAddresses(address.data);
    setData(newData);
  };

  const handleNoCustomerSelect = (name, value) => {
    const newData = { ...data };
    newData["clientName"] = value;

    setData({ ...data, [name]: value });
    setErrors({});
  };

  const handleChange = async (name, value) => {
    setData({ ...data, [name]: value });
    setErrors({});
  };

  return (
    <>
      <AppHeaderComponent
        context={context}
        theme={context.theme}
        name="tickets"
        subpage="create"
        saveBtn={handleSave}
      />
      <ApiLoader status={loading.status} data={loading.data} />
      <div style={{ paddingTop: 140, paddingBottom: 40 }}>
        <TicketCreateForm
          handleChange={handleChange}
          data={data}
          type="create"
          companies={companies}
          groups={groups}
          users={users}
          com_type={com_type}
          categories={categories}
          addresses={addresses}
          handleCompanySelect={handleCompanySelect}
          setCompanies={setCompanies}
          noneCustomer={noneCustomer}
          handleNoCustomerSelect={handleNoCustomerSelect}
          errors={errors}
          tags={tags}
          loading={loading}
          contacts={contacts}
          handleContactChange={handleContactChange}
          handleSiteChange={handleSiteChange}
          selected={selected}
          sites={sites}
          setSelected={setSelected}
        />
        <KnowledgeBaseSearch />
      </div>
    </>
  );
}
