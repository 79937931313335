import React, { useState, useEffect } from "react";
import ApiLoader from "../../components/common/ApiLoader";
import Loading from "../../components/common/Loading";
import TicketAcitivtyCreateForm from "../../components/modules/tickets/activity/TicketActivityCreateForm";
import { AppHeaderComponent, TicketActivitiesApi } from "@unity/components";
import { TextField } from "@mui/material";
import dayjs from "dayjs";

export default function TicketActivityView({ route, context }) {
  const [loading, setLoading] = useState({ status: false });
  const id = route.match.params.id;
  const activityid = route.match.params.activityid;
  const [data, setData] = useState(false);

  const handleCall = async () => {
    const result = await TicketActivitiesApi.getActivityRecord(id, activityid);
    setData(result.data);
  };

  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setData(data);
  };

  useEffect(() => {
    handleCall();

    return () => {};
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name={"tickets"}
          subpage={"activity"}
          subpage2={"view"}
        />
        <ApiLoader status={loading.status} data={loading.data} />
        <div style={{ paddingTop: 140 }}>
          {/* <TicketAcitivtyCreateForm data={data} handleChange={handleChange} /> */}
          <div className="block">
      <div className="container-fluid">
        <div className="form-row">
          <div className="form-group col-lg-12">
            <h4>Ticket Activity</h4>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-3">
            <TextField
              required
              label="Activity Name"
              type="text"
              name="name"
              fullWidth
              value={data.name} //In the form component, create the first input, and set its value to the current state of the ticket name
              onChange={(e) => handleChange(e)}
              helperText="Give the activity a name..."
            />
          </div>
          <div className="form-group col-lg-3">
            <TextField
              required
              label="Completed By"
              type="text"
              fullWidth
              name="completed_by"
              helperText="Who worked on this"
              value={data.completed_by}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="form-group col-lg-3">

                <TextField
              required
              label="Start Date"
              type="text"
              fullWidth
              name="completed_by"
              helperText="data and time started"
              value={ data.start ? dayjs(data.start).format('DD/MM/YYYY') : "" }
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="form-group col-lg-3">
                <TextField
              required
              label='End Date'
              type="text"
              fullWidth
              name='end' 
              helperText="data and time started"
              value={data.end ? dayjs(data.end).format('DD/MM/YYYY') : ""}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-12">
            <TextField
              label="Activity Notes"
              type="text"
              name="notes"
              fullWidth
              value={data.notes} //In the form component, create the first input, and set its value to the current state of the ticket name
              onChange={(e) => handleChange(e)}
              multiline
              helperText="Give the activity a name..."
            />
          </div>
        </div>
      </div>
    </div>
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
