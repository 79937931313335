import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from '@mui/material/Autocomplete';
import AttachmentForm from "./AttachmentForm";
import AttachmentList from "./AttachmentList";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, {  useState } from "react";
import { BusinessApi } from "@unity/components";
import { CircularProgress } from "@mui/material";

const INPUTWAIT = 1200;

export default function TicketCreateForm(props) {
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [noneCustomer, setNoneCustomer] = useState(props.type === "edit" ? props.noneCustomer  : false);
  const [inputTimeout, setInputTimeout] = useState(false);

  const status = [
    { id: 0, name: "Open" },
    { id: 1, name: "Closed" },
  ];

  const customerToggle = () => {
    if(!noneCustomer){
      // If it is an old ticket and has an old customer_id then we do not want to let them change to a non customer type of ticket.
      // This is because we can not delete this id and the ticket will not show correctly next time it is opened!!!
      if(!props.data.customer_id){
       
        setNoneCustomer(true);
        props.data["noneCustomer"] = true;
        if(props.data.business_id){
          props.data.clientName = null;
          props.data.address_id = null;
          props.data.business_id = null;
          props.data.business_site_id = null; 
          props.data.customer_id = null;
          props.data.site_id = null;
          props.data.business_contact_id = null;
          props.data.site_contact_name = null;
          props.data.company_name = null;
          props.setSelected(false);
        }
      }
    }else{
      setNoneCustomer(false);
      props.data["noneCustomer"] = false;
      if(props.data.clientName){ delete props.data.clientName };
    }
  }

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };

  const getSelectedItem = () => {
    if(props.type == "edit"){
      // This is because after a ticket has been switched back from a non-customer style then there is no selected state so the form breaks!
      if(props.selected){
        const item = props.companies.find(
          (opt) => {
            return opt._id == props.selected.business._id;
          }
        );
        return item;
      }else{
        return null;
      }
    }else{
      let thisId = props.data.customer_id;
      if(props.data.business_id !== null && props.data.business_id !== undefined) {
        return thisId = props.data.business_id;
      }

      const item = props.companies.find(
        (opt) => opt._id == thisId
      );
      return item;
    }
  };

  const cssStyle = {
    style: {
      readOnly: true,
    },
  };

  const handleCompanyChange = async (input) => {
    try {
      const res = await BusinessApi.businessIndexFiltered({
        rules: input == "" ? [] : [
          {field: "customer_name", operator: "LIKE", value: `%${input}%`},
          {field: "is_client", operator: "=", value: true}
        ],
        page: 1
      });

      if(!res.success) throw "Call Failed";
      props.setCompanies(res.data.data);
      setInputTimeout(false);
    } catch (error) {
      alert(`Failed to load additional companies - ${error}`);
    }
  }

  if (!props.data.partial && props.data.customer_updated) {
    cssStyle.style.backgroundColor = "#ffb5b5";
  }

  if(props.loading?.status) return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <CircularProgress/>
      <Typography>
        Loading Form...
      </Typography>
    </div>
  )

  return (
    <div>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-10">
              <TextField
                label="Ticket Name"
                name="ticket_name"
                value={props.data.ticket_name}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Give the ticket a name..."
                fullWidth
              />
            </div>

            <div className="form-group col-lg-2">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>No</Typography>
                      <Switch
                        onChange={() => {
                          customerToggle()
                        }}
                        checked={noneCustomer}
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  }
                  label="Not a Customer?"
                  labelPlacement="Top"
                />
              </FormGroup>
            </div>
          </div>

          <div className="form-row">
            {!noneCustomer && props.type !== "view" ? (
              <div className="form-group col-lg-4">
                <Autocomplete
                  className="company_autocomplete"
                  defaultValue={getSelectedItem()}
                  fullWidth
                  onChange={(e, val) => props.handleCompanySelect(val)}
                  onInputChange={(e, input) => {
                    if(inputTimeout) clearTimeout(inputTimeout);
                    setInputTimeout(
                      setTimeout(() => {
                        handleCompanyChange(input);
                      }, INPUTWAIT)
                    );
                  }}
                  loading={inputTimeout}
                  options={props.companies}
                  getOptionLabel={(option) => option.customer_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={props.errors.clientName}
                      label="Company Name"
                    />
                  )}
                />
              </div>
            ) : (
              <div className="form-group col-lg-4">
                <TextField
                  label="Customer Name"
                  name="clientName"
                  value={props.data.clientName ? props.data.clientName : ""}
                  onChange={(e) =>
                    props.handleNoCustomerSelect(e.target.name, e.target.value)
                  }
                  fullWidth
                />
              </div>
            )}

            {!noneCustomer && (
              <>
                <div className="form-group col-lg-4">
                  <Autocomplete
                    fullWidth
                    value={props.selected ? props.selected.site : ""}
                    isOptionEqualToValue={(option, value) => option._id == (value != undefined || value != null ? value["_id"] : null) }
                    disabled={!Array.isArray(props.sites) || props.sites && props.sites.length <= 0}
                    onChange={(e, val) => props.handleSiteChange(val)}
                    options={props.sites}
                    getOptionLabel={(option) =>
                      `${option.house_no} ${option.street}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Site Name" />
                    )}
                  />
                </div>

                <div className="form-group col-lg-4">
                  <Autocomplete
                    fullWidth
                    value={props.selected ? props.selected.contact :  props.data.category_id ? props.data.category_id : ""}
                    isOptionEqualToValue={(option, value) => option._id == (value != undefined || value != null ? value["_id"] : null) }
                    disabled={!Array.isArray(props.contacts) || props.contacts && props.contacts.length <= 0}
                    onChange={(e, val) => props.handleContactChange(val)}
                    options={props.contacts}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.surname}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Contact Name" />
                    )}
                  />
                </div>
              </>
            )}
          </div>

          <div className="form-row">
            <div className="col-lg-4">
              <InputLabel style={{ margin: 0, fontSize: "1rem" }}>
                Which business will this be allocated to?:
              </InputLabel>

              <Select
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth
                name="group_ref"
                value={props.data.group_ref}
              >
                {props.groups.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>

            <div className="col-lg-4">
              <InputLabel style={{ margin: 0, fontSize: "1rem" }}>
                Who is assigned to this?
              </InputLabel>
              <Select
                fullWidth
                style={{ zIndex: 999 }}
                name="allocate_id"
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                value={props.data.allocate_id}
              >
                {props.users.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.uuid}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className="col-lg-4">
              <InputLabel style={{ margin: 0, fontSize: "1rem" }}>
                Type of Contact:
              </InputLabel>
              <Select
                fullWidth
                displayEmpty
                name="com_type_id"
                value={props.data.com_type_id}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
              >
                {props.com_type.map((item, key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>

          <div className="form-row">
            
            <div className="col-lg-4">
              <InputLabel style={{ margin: 0, fontSize: "1rem" }}>
                Tag Type
              </InputLabel>
              <Select
                fullWidth
                displayEmpty
                name="tag"
                value={props.data.tag}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
              >
                {props.tags &&
                  props.tags.map((tag, key) => {
                    return (
                      <MenuItem key={key} value={tag.id}>
                        {tag.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>

            <div className="col-lg-4">
            <InputLabel style={{ margin: 0, fontSize: "1rem" }}>
                Nature of ticket:
              </InputLabel>
              <Select
                name="category_id"
                value={props.data.category_id}
                fullWidth
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                error={props.errors.category_id}
              >
                {props.categories.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            
          </div>
        </div>
      </div>

      <div className="block">
        <div className="container-fluid">
          {props.data.customer_closed && (
            <>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <TextField
                    name="customerClosure"
                    fullWidth
                    value="Customer has requested the closure of the ticket!"
                    inputProps={{
                      style: {
                        readOnly: true,
                        color: "red",
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )}

          {props.type !== "create" && (
            <div className="form-row">
              <div className="col-lg-4">
                <InputLabel>Current Status</InputLabel>
                <Select
                  fullWidth
                  name="current_status"
                  onChange={(e) =>
                    props.handleChange(e.target.name, e.target.value)
                  }
                  value={props.data.current_status}
                >
                  {status.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="col-lg-4">
                <InputLabel>Created By</InputLabel>
                <TextField
                  name="created_by_name"
                  value={props.data.created_by_name}
                  disabled
                  fullWidth
                />
              </div>
              <div className="form-group col-lg-4">
              <InputLabel>Ticket Reference</InputLabel>
              <TextField
                name="ticket_id"                
                fullWidth
                disabled
                value={props.data.id}
              />
            </div>
            </div>
          )}

          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                label="Product Name"
                name="product_name"
                value={props.data.product_name}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Enter a product name"
                fullWidth
              />
            </div>
          </div>

          {props.data.customer_update && (
            <>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <TextField
                    label="Customer Update"
                    name="customerNotes"
                    multiline
                    fullWidth
                    value={props.data.customer_update}
                    inputProps={cssStyle}
                  />
                </div>
              </div>
            </>
          )}

          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                label="Additional Notes:"
                name="notes"
                value={props.data.notes}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Any additional notes? Add them here!..."
                multiline
                fullWidth
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                label="SLA Notes"
                name="sla_notes"
                value={props.data.sla_notes}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Enter sla notes"
                fullWidth
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Reported By"
                name="reported_by"
                value={props.data.reported_by}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Who reported this"
                fullWidth
              />
            </div>

            <div className="form-group col-lg-4">
              <InputLabel style={{ fontSize: 13, marginBottom: 3 }}>
                Contact Phone Number
              </InputLabel>
              <TextField
                name="clientNumber"
                value={props.data.clientNumber}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Customer Contact Phone Number"
                fullWidth
                error={props.errors.clientNumber}
              />
            </div>

            <div className="form-group col-lg-4">
              <InputLabel style={{ fontSize: 13, marginBottom: 3 }}>
                Contact Email
              </InputLabel>

              <TextField
                name="clientEmail"
                value={props.data.clientEmail}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Customer Contact Email"
                fullWidth
                error={props.errors.clientEmail}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="col-lg-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.data.ticket_prioritised}
                    onChange={(e) =>
                      props.handleChange(e.target.name, e.target.checked)
                    }
                    name="ticket_prioritised"
                    color="primary"
                  />
                }
                label="Priority Ticket"
              />
            </div>
          </div>
        </div>
      </div>

      {props.type === "edit" &&
      (
        <div className="block">
          <div className="container-fluid">
            <h4>Attachments:</h4>
            <AttachmentForm
              context={props.context}
              ticket_id={props.data.id}
              triggerChange={triggerChange}
            />
            <AttachmentList
              context={props.context}
              ticket_id={props.data.id}
              fetchTrigger={fetchTrigger}
            />
          </div>
        </div>
      )}

    </div>
  );
}
