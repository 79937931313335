import { TextField } from "@material-ui/core";
import React, { useContext } from "react";
import AuthContext from "../../services/AuthContext";
import AppButton from "../../components/common/AppButton";

export default function JobCostingSettingsOptions({
  handleItemsUpdate,
  handleItemSaveCancel,
  handleItemDelete,
  handleItemRestore,
  handleItemChange,
  handleAdd,
  type,
  fields,
  editActive,
  setEditActive,
  data,
  title,
  description,
}) {
  const context = useContext(AuthContext);

  return (
    <div className="block" style={{ padding: 15 }}>
      <h6 style={{ margin: 0 }}>{title}</h6>
      <sub>{description}</sub>

      {editActive === type ? (
        <>
          <AppButton
            onClick={() => setEditActive(false)}
            text="cancel"
            btnIcon="close"
            style={{
              marginTop: 10,
              marginRight: 10,
              marginBottom: 10,
              color: "white",
              background: "red",
            }}
          />
          <AppButton
            onClick={() => handleItemsUpdate(type)}
            text="save"
            btnIcon="check"
            style={{
              marginTop: 10,
              marginBottom: 10,
              color: "white",
              background: context.theme.sidebar.background,
            }}
          />
        </>
      ) : (
        <AppButton
          onClick={() => setEditActive(type)}
          text="Edit"
          btnIcon="edit"
          style={{
            marginTop: 10,
            marginBottom: 10,
            color: "white",
            background: context.theme.sidebar.background,
          }}
        />
      )}

      {data.map((item, key) => {
        return (
          <div style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                paddingBottom: 5,
                paddingTop: 5,
                marginBottom: 5,
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 4 }}>
                <TextField
                  value={item.name}
                  type={fields}
                  disabled={editActive !== type || (item.id && !item.active)}
                  onChange={(e) => handleItemChange(e, type, key)}
                />
              </div>
              <div style={{ justifyContent: "flex-end" }}>
                {editActive === type ? (
                  item.id && item.active ? (
                    <AppButton
                      onClick={() => handleItemDelete(item.id)}
                      btnIcon="delete"
                      style={{ color: "white", background: "red" }}
                    />
                  ) : item.id && !item.active ? (
                    <AppButton
                      onClick={() => handleItemRestore(item.id)}
                      btnIcon="restore-from-trash"
                      style={{ color: "white", background: "green" }}
                    />
                  ) : (
                    <AppButton
                      onClick={() => handleItemSaveCancel(type, key)}
                      btnIcon="close"
                      style={{
                        color: "white",
                        background: "red",
                        marginRight: 5,
                      }}
                    />
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {editActive === type && (
        <AppButton
          onClick={() => handleAdd(type)}
          btnIcon="add"
          style={{ color: "white", background: "red" }}
        />
      )}
    </div>
  );
}
