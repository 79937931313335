import React, { useEffect, useState } from "react";
export default function ReportBox({ title, data, bg }) {
  return (
    <>
      <div className="report-counter" style={{ backgroundColor: bg }}>
        <div className="container-fluid text-center">
          <div className="row ">
            <div className="col-lg-12 col-md-12 ">
              <p
                className="report-title"
                style={{ color: "white", fontWeight: 600, fontSize: "24px" }}
              >
                {title}
              </p>
            </div>
          </div>
          <p style={{ color: "white" }} className="count">
            {data}
          </p>
        </div>
      </div>
    </>
  );
}
